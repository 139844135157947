import React, { useEffect, useState } from "react"
import SliderCustom from "../sliderCustom/sliderCustom"
import Utils from "../../utils/utils"
import "./blockFormule.scss"
import BlockContainerList from "../blockContainerList/BlockContainerList"
import tracking from "../../utils/tracking"

const constructList = data => {
  return (
    <>
      {data.map((el, index) => (
        <li key={index + "li"} className="px-1 li">
          <div className={" item d-f fd-c ai-c  p-r br-12 " + el.name}>
            <h3 className="c-1 fs-28 ff-gb ta-md-c">{el.labelFormule}</h3>
            <p className="cg-7 lh-24 fs-16 ff-gm mb-0">
              A partir de {el.price?.toFixed(2)}€/mois
            </p>
            <div
              className={" mt-3 mb-3"}
              style={{ height: "76px", width: "76px" }}
            >
              <img src={el.icon} alt=""></img>
            </div>
            <p className="lh-32 fs-18 cg-7 ff-gb">{el.description}</p>
            <div
              className="ul-detail-offre"
              dangerouslySetInnerHTML={{ __html: el.details }}
            ></div>
            <div className="link">
              <a
                onClick={() =>
                  tracking.trackClick(
                    "click.action",
                    el.name === "initiale"
                      ? "formule_initiale"
                      : "formule_integrale"
                  )
                }
                href={el.url}
                className="btn-select-round btn-hover-c1 td-n w-s-n bc-1 c-w"
              >
                {el.cta}
              </a>
            </div>
          </div>
        </li>
      ))}
    </>
  )
}

const BlockFormule = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(Utils.isMobileSize())
    })
    setIsMobile(Utils.isMobileSize())
  }, [])

  return (
    <div className="block-offer py-7 d-f fd-c jc-c ai-c">
      <h2 className="cg-7 lh-48 ff-gb fs-34 ta-c">{data.title}</h2>
      <div className="py-5 w-100 d-f jc-c">
        <ul className="slider-offer ul ">
          {isMobile ? (
            <SliderCustom
              settings={{
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
              }}
            >
              {data.formules.map((el, index) => (
                <li key={index} className="px-1 px-md-2 li">
                  <div
                    className={
                      " item d-f fd-c ai-c box-shadow-md-m p-r br-12 " + el.name
                    }
                  >
                    <h3 className="c-1 fs-28 ff-gb ta-md-c ">
                      {el.labelFormule}
                    </h3>
                    <p className="cg-7 lh-24 fs-16 ff-gm mb-0">
                      A partir de {el.price}€/mois
                    </p>
                    <div
                      className={" mt-3 mb-3"}
                      style={{ height: "76px", width: "76px" }}
                    >
                      <img src={el.icon} alt=""></img>
                    </div>
                    <p className="lh-32 fs-18 cg-7 ff-gb ta-md-c">
                      {el.description}
                    </p>
                    <div
                      className="ul-detail-offre"
                      dangerouslySetInnerHTML={{ __html: el.details }}
                    ></div>

                    <div className="link">
                      <a
                        onClick={() =>
                          tracking.trackClick(
                            "click.action",
                            el.name === "initiale"
                              ? "formule_initiale"
                              : "formule_integrale"
                          )
                        }
                        href={el.url}
                        className="btn-select-round btn-hover-c1 td-n w-s-n bc-1 c-w"
                      >
                        {el.cta}
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </SliderCustom>
          ) : (
            <>{constructList(data.formules)}</>
          )}
        </ul>
      </div>
      <p className="ta-c cg-3 fs-14 lh-24 ff-gb d-md-n mb-0 mt-4">
        Et pour les deux formules :{" "}
      </p>
      <BlockContainerList>
        {data.pedago.map((el, index) => (
          <li key={index} className="li d-f ai-c py-md-1">
            <span
              style={{
                height: "20px",
                minWidth: "20px",
                width: "20px",
                borderRadius: "50%",
              }}
              className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
            ></span>
            <p className="mb-0 fs-14 lh-24 ff-gm cg-7">{el.label}</p>
          </li>
        ))}
      </BlockContainerList>
    </div>
  )
}

export default BlockFormule

import React from "react"
import "./placeholder.scss"
const Placeholder = () => {
  return (
    <div className="placeholder-load w-100 px-2 ">
      <div className="content d-f fd-c p-4 box-shadow-m">
        <div className="d-f ai-c">
          <div>
            <div
              className=""
              style={{
                height: "40px",
                width: "40px",
                minWidth: "30px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <div className="animated-background"></div>
            </div>
          </div>
          <div className="ml-2 w-100 br-12 oh" style={{ height: "40px" }}>
            <div className="animated-background"></div>
          </div>
        </div>

        <div style={{ height: "200px" }} className="w-100 br-12 oh mt-2">
          <div className="animated-background"></div>
        </div>
      </div>
    </div>
  )
}

export default Placeholder

import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo/seo"
import "../assets/styles/app.scss"
import "./index.scss"
import BlockAvis from "../components/blockAvis/BlockAvis"
import BlockSteps from "../components/blockSteps/BlockSteps"
import BlockFaq from "../components/blockFaq/BlockFaq"
import BlockFormule from "../components/blockFormule/BlockFormule"
import BlockSolution from "../components/blockSolution/blockSolution"
import BlockApp from "../components/blockApp/blockApp"
import BlockExperience from "../components/blockExperience/BlockExperience"
import utils from "../utils/utils"
import { useStaticQuery, graphql } from "gatsby"
import { getTaxoLien } from "../hooks/hooks"
import ImgCustom from "../components/imgCustom/imgCustom"
import links from "../utils/links"
import tracking from "../utils/tracking"
import config from "../utils/config"

const pages = [
  { slug: "/offre", label: "Offre" },
  { slug: "/offre-initial", label: "Offre Initiale" },
  { slug: "/diagnostic", label: "Diagnostic" },
  { slug: "/404", label: "404" },
]

const IndexPage = () => {
  const [campagne, setCampagne] = useState("")

  useEffect(() => {
    if (typeof window !== 'undefined'){
      if (localStorage.getItem("campaign") == null && config.URL_VEL && window.location.href.replace(config.URL_VEL + "/", "") != "" && window.location.href.replace(config.URL_VEL, "") != ""){
        localStorage.setItem("campaign", window.location.href.replace(config.URL_VEL + "/", ""))
      }
      else if (localStorage.getItem("campaign") != null && config.URL_VEL && (window.location.href.replace(config.URL_VEL + "/", "") == "" || window.location.href.replace(config.URL_VEL, "") == "")){
        localStorage.removeItem("campaign")
      }
    }
    else {
    }  
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem("campaign") != null){
      setCampagne(localStorage.getItem("campaign"))
    }
  }, [])

  const dataGraphQl = useStaticQuery(graphql`
    query MyQuery {
      nodeHomepage {
        field_seo_titre
        field_seo_description
        field_titre_formule
        field_titre_materiel
        field_titre_avis {
          value
        }
        field_titre_
        field_titre_abonnement
        field_cta_materiel
        field_cta_faq
        field_cta_
        field_cover_materiel {
          alt
        }
        field_cover_faq {
          alt
        }
        field_cover_avis {
          alt
        }
        relationships {
          field_block_application {
            field_sous_titre_ligne
            field_titre
            field_min_description
            field_cover {
              alt
            }
            field_cover_en_mobile {
              alt
            }
            field_image_application {
              alt
            }
            field_image_application_mobile {
              alt
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_cover_en_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_image_application {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_image_application_mobile {
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
          }
          field_cover_materiel {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: NO_CHANGE)
              }
            }
          }
          field_cover_avis {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: NO_CHANGE)
              }
            }
          }
          field_cover_faq {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, formats: NO_CHANGE)
              }
            }
          }
          field_step_illustration_abonneme {
            weight
            field_titre
            relationships {
              field_picto {
                localFile {
                  publicURL
                }
              }
            }
          }
          field_faq_detail {
            title
            relationships {
              field_category {
                name
              }
            }
            body {
              value
            }
          }
          field_hero_header {
            field_cover {
              alt
            }
            field_titre_hero {
              value
            }
            field_sous_titre {
              value
            }
            relationships {
              field_cover {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_tablette {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
              field_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, formats: NO_CHANGE)
                  }
                }
              }
            }
          }
        }
        field_description_materiel {
          value
        }
      }
      allTaxonomyTermFormule {
        nodes {
          name
          field_sample_liste_formule {
            value
          }
          field_sample_titre_formule {
            value
          }
          relationships {
            field_sample_picto {
              localFile {
                publicURL
              }
            }
          }
          field_label
          field_prix
          field_sample_cta
          weight
        }
      }
    }
  `)

  const blockSolution = utils.mapHeroHeader(dataGraphQl, "nodeHomepage")
  const blockFormule = {
    title: dataGraphQl.nodeHomepage.field_titre_formule,
    formules: dataGraphQl.allTaxonomyTermFormule.nodes
      .map(el => ({
        name: el.name,
        labelFormule: el.field_label,
        description: el.field_sample_titre_formule.value,
        price: el.field_prix,
        icon: el.relationships.field_sample_picto.localFile.publicURL,
        details: el.field_sample_liste_formule.value,
        url: links.getLink("formule") + `#${el.name}` + campagne,
        weight: el.weight,
        cta: el.field_sample_cta,
      }))
      .sort((a, b) => (a.weight > b.weight ? 1 : -1)),
    pedago: [
      { label: "Protection des intrusions & des incendies" },
      { label: "Matériel discret et ergonomique" },
      { label: "Application mobile avec vidéo live HD" },
    ],
  }
  const blockMaterial = {
    title: dataGraphQl.nodeHomepage.field_titre_materiel,
    text: dataGraphQl.nodeHomepage.field_description_materiel.value,
    cover: {
      path: dataGraphQl.nodeHomepage.relationships.field_cover_materiel
        .localFile,
      alt: dataGraphQl.nodeHomepage.field_cover_materiel.alt,
    },
    url: {
      label: dataGraphQl.nodeHomepage.field_cta_materiel,
      url: links.getLink("materiel") + campagne,
    },
  }
  const blockAvis = {
    title: dataGraphQl.nodeHomepage.field_titre_avis.value,
    cover: {
      path: dataGraphQl.nodeHomepage.relationships.field_cover_avis.localFile,
      alt: dataGraphQl.nodeHomepage.field_cover_avis.alt,
    },
    cta: dataGraphQl.nodeHomepage.field_cta_,
    url: getTaxoLien("avis"),
  }
  const blockSteps =
    dataGraphQl.nodeHomepage.relationships.field_step_illustration_abonneme
      ?.sort((a, b) => (a.weight > b.weight ? 1 : -1))
      .map(el => ({
        label: el.field_titre,
        icon: {
          path: el.relationships.field_picto.localFile.publicURL,
        },
      }))
  const blockApp = utils.mapApp(
    dataGraphQl.nodeHomepage.relationships.field_block_application
  )
  const blockFaq = {
    title: dataGraphQl.nodeHomepage.field_titre_,
    cta: dataGraphQl.nodeHomepage.field_cta_faq,
    cover: {
      path: dataGraphQl.nodeHomepage.relationships.field_cover_faq.localFile,
      alt: dataGraphQl.nodeHomepage.field_cover_faq.alt,
    },
    faqList: dataGraphQl.nodeHomepage.relationships.field_faq_detail.map(
      (el, index) => ({
        id: index,
        label: el.relationships.field_category.name,
        title: el.title,
        content: el.body.value,
      })
    ),
  }
  const faqList = [
    {
      id: 0,
      label: "Installation",
      title: "Combien de temps faut-il pour installer le système d’alarme ?",
      content:
        "Pour un système d’alarme maison standard, quelques heures sont suffisantes pour que le technicien installe l’ensemble du système. Pour un système d’alarme non standard, cela peut prendre plus de temps. N’hésitez pas à contacter un conseiller Nexecur pour plus d’informations sur nos systèmes de sécurité.",
    },
    {
      id: 1,
      label: "Application mobile",
      title:
        "Comment activer la fonction touch id (déverouilage par empreinte digitale) de l'application mobile ?",
      content:
        "Pour un système d’alarme maison standard, quelques heures sont suffisantes pour que le technicien installe l’ensemble du système. Pour un système d’alarme non standard, cela peut prendre plus de temps. N’hésitez pas à contacter un conseiller Nexecur pour plus d’informations sur nos systèmes de sécurité.",
    },
    {
      id: 2,
      label: "Certifications",
      title: "Le matériel de télésurveillance nexecur est-il certifié ?",
      content:
        "Pour un système d’alarme maison standard, quelques heures sont suffisantes pour que le technicien installe l’ensemble du système. Pour un système d’alarme non standard, cela peut prendre plus de temps. N’hésitez pas à contacter un conseiller Nexecur pour plus d’informations sur nos systèmes de sécurité.",
    },
    {
      id: 3,
      label: "Certifications",
      title: "Que signifie la certification NFA2P du matériel Nexecur ?",
      content:
        "Pour un système d’alarme maison standard, quelques heures sont suffisantes pour que le technicien installe l’ensemble du système. Pour un système d’alarme non standard, cela peut prendre plus de temps. N’hésitez pas à contacter un conseiller Nexecur pour plus d’informations sur nos systèmes de sécurité.",
    },
    {
      id: 4,
      label: "Maintenance et matériel",
      title:
        "Et si mon système d’alarme tombe en panne ? L’entretien du matériel est-il assuré par Nexecur ?",
      content:
        "Pour un système d’alarme maison standard, quelques heures sont suffisantes pour que le technicien installe l’ensemble du système. Pour un système d’alarme non standard, cela peut prendre plus de temps. N’hésitez pas à contacter un conseiller Nexecur pour plus d’informations sur nos systèmes de sécurité.",
    },
  ]

  const seoPage = {
    title: dataGraphQl.nodeHomepage.field_seo_titre,
    description: dataGraphQl.nodeHomepage.field_seo_description,
  }

  useEffect(() => {
    /** Tracking */
    tracking.track("typeTemplate", "homepage")
    tracking.track("page_name", seoPage.title + campagne)
    tracking.track("categorie", "accueil")
    tracking.trackInit()
    utils.startPageWindow()
  }, [])
  return (
    <Layout isViewPromoOnHeader={true}>
      <Seo title={seoPage.title} description={seoPage.description} />
      <section className="bc-md-w">
        <div className="wrapper">
          {/** BLOCK INDEX TOP */}
          <BlockSolution data={blockSolution} />
        </div>
      </section>
      {/** END BLOCK INDEX TOP */}
      <section className="bcg-md-2">
        <div className="wrapper oh">
          <BlockFormule data={blockFormule} />
        </div>
      </section>
      {/** BLOCK MATERIAL */}
      <section className="block-cover-material p-r d-f fd-md-c ai-c">
        <div className="gatsby-img-cover of-cover w-100 h-100 p-a p-md-r">
          <ImgCustom
            src={blockMaterial.cover.path}
            alt={blockMaterial.cover.alt}
          />
        </div>
        {/*<img
          className="of-cover w-100 h-100 p-a p-md-r"
         
        ></img>*/}
        <div className="wrapper d-f ai-c jc-fe  ">
          <div className="container-wording p-5 px-md-0 py-md-5 bc-w br-12 d-f fd-c ai-c jc-c mx-md-a">
            <h3 className="cg-7 fs-34 lh-48 ff-gb mb-2 f-w-n ta-c">
              {blockMaterial.title}
            </h3>
            <div
              className="description-materials mb-3"
              dangerouslySetInnerHTML={{ __html: blockMaterial.text }}
            ></div>
            <a
              href={blockMaterial.url.url}
              className="btn-select-round btn-hover-c1-line with-line td-n w-s-n bc-w c-1 f-w-b"
            >
              {blockMaterial.url.label}
            </a>
          </div>
        </div>
      </section>
      {/** END BLOCK MATERIAL */}
      {/** BLOCK AVIS */}
      <section className="bcg-2 py-5 d-f fd-c ai-c jc-c">
        <BlockAvis data={blockAvis} />
        <a
          href={blockAvis.url}
          target="_blank"
          className="btn-hover-cb-line d-ib td-n mt-5 btn-select-round with-line-black td-n w-s-n cg-7 f-w-b"
        >
          {blockAvis.cta}
        </a>
      </section>
      {/** END BLOCK AVIS */}
      {/** BLOCK STEPS */}
      <section className="pt-8">
        <div className="wrapper">
          <BlockSteps
            title={dataGraphQl.nodeHomepage.field_titre_abonnement}
            data={blockSteps}
          />
        </div>
        {/** BLOCK APP */}
        <BlockApp data={blockApp} />
        {/** END BLOCK APP */}
      </section>
      {/** BLOCK FAQ */}
      <section className="wrapper">
        <div className="d-f fd-c ai-c jc-c">
          <h3 className="ta-c cg-7 ff-gb fs-34 lh-48">
            Les questions les plus fréquentes
          </h3>
          {/*** ILLUSTRATION FAQ */}
          <div className="d-f ai-fs">
            <div className="d-f fd-c ai-c jc-c px-12 py-13 d-md-n">
              <div className="illustration-faq">
                <ImgCustom src={blockFaq.cover.path} alt={blockFaq.cover.alt} />
                {/*<img src={blockFaq.cover.path} alt={blockFaq.cover.alt}></img>*/}
              </div>
              <a
                href={links.getLink("faq") + campagne}
                className="d-ib td-n mt-5 btn-hover-c1-line btn-select-round with-line td-n w-s-n bc-w c-1"
              >
                {blockFaq.cta}
              </a>
            </div>
            {/** LISTE FAQ */}
            <div className="d-f fd-c">
              <BlockFaq data={blockFaq.faqList} />
              <div className="mx-a">
                <a
                  href={links.getLink("faq") + campagne}
                  className="d-n d-md-ib td-n mt-5 mb-5 btn-hover-c1-line btn-select-round with-line td-n w-s-n bc-w c-1"
                >
                  {blockFaq.cta}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/** BLOCK EXPERIENCE */}
      <BlockExperience />
    </Layout>
  )
}

export default IndexPage


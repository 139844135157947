import React, { useState } from "react"
import "./blockFaq.scss"
import { propsFaq } from "../../models/block"

const BlockFaq: React.FC<propsFaq> = ({ data }) => {
  const [selected, setSelected] = useState(null)
  return (
    <ul className="block-faq ul">
      {data.map(el => (
        <li key={el.id} className="line py-3 li">
          <div
            className="container d-f ai-c jc-sb"
            onClick={() => setSelected(selected !== el.id ? el.id : null)}
          >
            <div>
              <p className="c-1 fs-16 lh-24 mb-0 ">{el.label}</p>
              <p className="cg-7 fs-16 lh-24 mb-0">{el.title}</p>
            </div>
            <button
              className={"icon-arrow-down-c1"}
              style={el.id === selected ? { transform: "rotate(180deg)" } : {}}
            ></button>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: el.content }}
            className={
              selected === el.id
                ? "content mt-2 ff-gbook cg-7 fs-16 lh-25 is-active"
                : "content mt-2 ff-gbook cg-7 fs-16 lh-25 "
            }
          ></div>
        </li>
      ))}
    </ul>
  )
}

export default BlockFaq

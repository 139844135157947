import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./blockAvis.scss"
import SliderCustom from "../sliderCustom/sliderCustom"
import CountStars from "../countStars/CountStars"
import config from "../../utils/config"
import axios from "axios"
import moment from "moment"
import momentLocale from "../../utils/momentCustom"
import ImgCustom from "../imgCustom/imgCustom"
import Placeholder from "../placeholder/placeholder"
import tracking from "../../utils/tracking"
import { graphql, useStaticQuery } from "gatsby"
momentLocale

class TrustpilotAvis {
  content: string;
  note: Number;
  title: string;
  date: string;

  constructor(content: string, note: Number, title: string, date: string){
    this.content = content;
    this.note = note;
    this.title = title; 
    this.date = date;
  }
}

const BlockAvis = ({ data }) => {
  const [isDataGlobalLoaded, setIsDataGlobalLoaded] = useState(false)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isFlagLoaded, setIsFlagLoaded] = useState(false)
  const [isShowingReco, setIsShowingReco] = useState(false)
  const [dataGlobalTrustpilot, setDataGlobalTrustpilot] = useState([])
  const [dataTrustpilot, setDataTrustpilot] = useState([])
  const [avisTrustpilot, setAvisTrustpilot] = useState([TrustpilotAvis])
  const [load, setLoad] = useState(false)

  const dataGraphQl = useStaticQuery(graphql`
    query allTaxoTauxReco {
      allTaxonomyTermAvisAffichageTauxDeReco {
        nodes {
          field_affichage_taux_de_reco
        }
      }
    }
  `)

  useEffect(() => {
    setIsShowingReco(dataGraphQl.allTaxonomyTermAvisAffichageTauxDeReco.nodes[0].field_affichage_taux_de_reco)
    setIsFlagLoaded(true)
  })

  const queryDataGlobalTrustpilot = () => {
    axios
      .get("https://api.trustpilot.com/v1/business-units/find?apikey=Jz94wZ2KncAmyS2dZ3op3BVvTlJTDxbZ&name=nexecur.fr")
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            setDataGlobalTrustpilot(res.data)
            setIsDataGlobalLoaded(true)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }
  
  const queryDataTrustpilot = () => {
    axios
      .get("https://api.trustpilot.com/v1/business-units/56b0bfbf0000ff00058867a6/reviews?apikey=Jz94wZ2KncAmyS2dZ3op3BVvTlJTDxbZ&perPage=30")
      .then(res => {
        if (res.status === 200) {
          if (res.data) {
            setDataTrustpilot(res.data)
            avisTrustpilot.pop();
            var nbAvisRecolte = 0
            res.data.reviews.forEach((avis) => {
              if (nbAvisRecolte < 3){
                if (avis.text.length > 99 && avis.title.length > 1 && avis.stars >= 4){
                  var date = new Date(avis.experiencedAt)
                  var dateTxt = "Abonnée depuis " + date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear().toString() //date.getMonth().toString() + " " + date.getFullYear().toString()
                  avisTrustpilot.push(new TrustpilotAvis(avis.text, avis.stars, avis.title, dateTxt))
                  nbAvisRecolte += 1;
                }
              }
            });
            setIsDataLoaded(true)
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (isDataGlobalLoaded && isDataLoaded && isFlagLoaded) {
      setLoad(true)
    }
  }, [isDataGlobalLoaded, isDataLoaded, isFlagLoaded])

  useEffect(() => {
    queryDataGlobalTrustpilot()
    queryDataTrustpilot()
  }, [])

  return (
    <div className="block-user-rate oh w-100">
      <div className="wrapper d-f fd-c ai-c">
        <h3 className="cg-7 lh-48 ff-gb fs-34 ta-c mb-5">{data.title}</h3>
      </div>
      <div className="d-f ai-fs jc-c w-100">
        <div className="illustration p-r br-12 oh d-md-n">
          <div className="gatsby-img-cover">
            <ImgCustom src={data.cover.path} alt={data.cover.alt} />
          </div>
          {/*<img
            className="of-cover w-100 h-100 p-a"
           
          ></img>*/}
        </div>

        <div className="container-text px-md-2">
          {load === true ? (
            <>
              {/** WORDING */}
              {(dataGlobalTrustpilot && dataTrustpilot) && (
                <div className="d-f ai-c mx-a pl-5 pl-md-2">
                  <div className="icon-avis"></div>
                  <div className="pl-2">
                    {isShowingReco && (
                    <p className="cg-7 mb-2">
                      <span className="fs-24 c-1 ff-gb">
                        {
                        parseInt((((dataGlobalTrustpilot.numberOfReviews.fiveStars + dataGlobalTrustpilot.numberOfReviews.fourStars) / dataGlobalTrustpilot.numberOfReviews.total) * 100).toString())
                        }%{" "}
                      </span>
                      de nos abonnés recommandent Nexecur Protection
                    </p>
                    )}
                    <div className="d-f ai-c">
                      <CountStars
                        value={dataGlobalTrustpilot.score.trustScore}
                        sizeHeight="15px"
                        sizeWidth="15px"
                        margeRightContainer="6px"
                      />
                      <a
                        className="c-1"
                        target="_blank"
                        onClick={() =>
                          tracking.trackClick(
                            "click.navigation",
                            "avis_clients"
                          )
                        }
                        href={"https://fr.trustpilot.com/review/nexecur.fr"}
                      >
                        {dataGlobalTrustpilot.numberOfReviews.total} avis
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {/** SLIDER */}
              <div className="slider-avis">
                <SliderCustom>
                  {avisTrustpilot.map((element, index) => (
                    <div key={index} className="px-5 py-2 px-md-2">
                      <div className="slider-item box-shadow-s br-22 py-3 px-5 bc-w">
                        <CountStars value={element.note} />
                        <p className="ff-gb cg-7 mt-2">{element.title}</p>
                        <p className="ff-gbook cg-7 fs-14 lh-24">
                          {element.content}
                        </p>
                        <p className="cg-3 ff-gm fs-12 lh-16 mb-3">
                          {element.date}
                        </p>
                        <p className="cg-3 ff-gm fs-14 lh-24 d-f ai-c">
                          <span
                            style={{
                              height: "20px",
                              minWidth: "20px",
                              width: "20px",
                              borderRadius: "50%",
                            }}
                            className="icon-check-white mr-1 bc-1 d-f ai-c jc-c"
                          ></span>
                          {"Avis authentique récolté via Trustpilot"}
                        </p>
                      </div>
                    </div>
                  ))}
                </SliderCustom>
              </div>
            </>
          ) : (
            <Placeholder />
          )}
        </div>
      </div>
    </div>
  )
}

export default BlockAvis
